.operationControlTest > div {
  width: 100%;
  justify-content: space-between;
}
.operationControlTest .el-form {
  display: flex;
}
.operationControlTest .el-form .el-form-item__label {
  padding: 0 8px 0 0 !important;
}
.operationControlTest .el-form .el-form-item {
  margin-bottom: 0;
}
.operationControlTest .el-form .btns .el-form-item__content {
  margin-left: 0 !important;
}
.textRed {
  color: red;
}
